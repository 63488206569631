import React from 'react';

const SpriteIconViewer = ({ className = '', spriteId }) => {
  return (
    <>
      {spriteId ? (
        <svg className={`icon ${className}`}>
          <use href={`#${spriteId}`}></use>
        </svg>
      ) : null}
    </>
  );
};

export default SpriteIconViewer;
