import React from 'react';
import { Link } from 'gatsby';
import Button from 'antd/lib/button';
import SEO from '@components/SEO';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import '@scss/main.scss';

const NotFoundPage = () => (
  <div className="not-found-section">
    <header className="header">
      <div className="menu">
        <Link to="/">
          <SpriteIconViewer
            className="logo-container"
            spriteId="wanpng_black"
          />
        </Link>
      </div>
    </header>

    <SEO title="404 - Not found" />
    <div className="container">
      <div>
        <SpriteIconViewer className="icon-wrapper" spriteId="error" />
      </div>

      <div className="title">Page not found</div>
      <div className="label">
        The page you were looking for doesn't exist. You may have <br />
        mistyped the address or the page may have moved.
      </div>
      <Link to="/">
        <Button className="primary-btn back">Go back to home</Button>
      </Link>
    </div>
  </div>
);

export default NotFoundPage;
